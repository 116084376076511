<template>
  <ion-list mode="ios">
    <ion-item lines="none" @click="showPopover()" class="cursor-pointer bottom-divider-thin">
      <ion-label color="dark" mode="ios">Update Phone Number</ion-label>
    </ion-item>
    <ion-item v-if="currentUser.issue_resolution_status != 'Cannot Resolve' && $can(I.MODIFY_ACCOUNT_PROGRAM)" lines="none" @click="confirmChangeUserStatus('Cannot Resolve')" class="cursor-pointer bottom-divider-thin">
      <ion-label color="dark" mode="ios">Mark as Cannot Resolve</ion-label>
    </ion-item>
    <ion-item v-if="currentUser.issue_resolution_status != 'Updated' && $can(I.MODIFY_ACCOUNT_PROGRAM)" lines="none" @click="confirmChangeUserStatus('Updated')" class="cursor-pointer bottom-divider-thin">
      <ion-label color="dark" mode="ios">Mark as Updated</ion-label>
    </ion-item>
    <ion-item v-if="currentUser.issue_resolution_status != 'Blocked' && $can(I.MODIFY_ACCOUNT_PROGRAM)" lines="none" @click="confirmChangeUserStatus('Blocked')" class="cursor-pointer bottom-divider-thin">
      <ion-label color="dark" mode="ios">Mark as Blocked</ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
import { EventBus } from "@/services/Events.js";
import { addIcons } from "ionicons";
import { logOut, settings } from "ionicons/icons";
import { send as httpSend } from "@/services/Api";
import PopoverUpdatePhone from "@/components/Roster/PopoverUpdatePhone";

addIcons({
  "ios-logout": logOut.ios,
  "ios-settings": settings.ios
});

export default {
  name: "PopoverRosterProgramEligiblePatients",
  data() {
    return {
      currentUser: Object
    };
  },
  methods: {
    showPopover: function() {
      this.$ionic.popoverController.dismiss();
      this.$ionic.popoverController
        .create({
          component: PopoverUpdatePhone,
          mode: "ios",
          cssClass: "popover-width-lg",
          componentProps: {
            data: {
              accountId: this.currentUser.account_id,
              firstName: this.currentUser.first_name,
              lastName: this.currentUser.last_name,
              mrn: this.currentUser.mrn,
              pcpFirstName: this.currentUser.pcp_first_name,
              pcpLastName: this.currentUser.pcp_last_name,
              pcpTitle: this.currentUser.pcp_title,
              pcpSuffix: this.currentUser.pcp_suffix
            }
          }
        })
        .then(m => m.present());
    },
    confirmChangeUserStatus(status) {
      this.$ionic.popoverController.dismiss();

      this.$ionic.alertController
        .create({
          header: "Mark as " + status + "?",
          message: "This will change the eligibility status for this patient",
          mode: "ios",
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {}
            },
            {
              text: "Confirm",
              handler: () => {
                this.changeUserStatus(status);
              }
            }
          ]
        })
        .then(a => a.present());
    },
    changeUserStatus(status) {
      const method = "put";
      const path = document.config.updateEligibilityStatus;
      const payload = {
        pasId: this.currentUser.pas_id,
        issueResolutionStatus: status
      };

      httpSend({ path, method, payload, authToken: true })
        .then(() => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "Patient status was successfully updated to " + status,
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          this.$ionic.popoverController.dismiss();
          EventBus.$emit("reloadRosterView");
        })
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to change user status",
              message: "Please try again later",
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
</style>
