import formatPhoneNumber from "./formatPhoneNumber";

export default (number, format = true) => {
  const approvalRegEx = /^\+1\d{10}$/;
  const numberToValidate = format ? formatPhoneNumber(number) : number;

  const isValid = approvalRegEx.test(numberToValidate);

  return isValid;
};
