<template>
  <ion-grid class="full-width pad-twenty">
    <ion-row>
      <ion-col>
        <!-- {{ settingsData }} -->
        <h6>Update SMS Phone Number</h6>
      </ion-col>
    </ion-row>
    <ion-row v-if="error">
      <ion-col class="error-notification">
        <IconWarning />
        <ion-text>{{ error }}</ion-text>
      </ion-col>
    </ion-row>
    <ion-row v-if="firstName && lastName" class="margin-top-ten">
      <ion-col>
        <ion-card class="prime-patient-card">
          <ion-row class="ion-align-items-center">
            <ion-col size="auto">
              <div class="avatar" v-if="firstName && lastName">
                {{ firstName.charAt(0) + lastName.charAt(0) }}
              </div>
            </ion-col>
            <ion-col class="pad-left-ten">
              <div>
                <div class="prime-patient-card-name">
                  {{ firstName + ", " + lastName }}
                </div>
                <div class="prime-patient-card-info text-small">
                  <strong>ID: </strong><span class="mrn-holder">{{ mrn }}</span>
                  <div class="display-inline-block"><strong>PCP: </strong>{{ pcpTitle | formatDisplayName(pcpFirstName, pcpLastName) }}</div>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row class="margin-top-five">
      <ion-col>
        SMS Phone Number
        <input type="text" class="form-control" v-model="inputPhone" />
      </ion-col>
    </ion-row>
    <ion-row class="margin-top-fifty">
      <ion-col>
        <button class="prime-button button-block" @click="checkForm" :class="{ 'button-pending': isLoading }">
          <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
          <span>Update Phone Number</span>
        </button>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { EventBus } from "@/services/Events.js";
import IconWarning from "@/components/Global/Icons/IconWarning";
import { send as httpSend } from "@/services/Api";
import isValidPhoneNumber from "@/utils/FormHelpers/isValidPhoneNumber";
import formatPhoneNumber from "@/utils/FormHelpers/formatPhoneNumber";
import displayPhoneNumber from "@/utils/FormHelpers/displayPhoneNumber";

export default {
  name: "PopoverUpdatePhone",
  components: {
    IconWarning
  },
  data() {
    return {
      accountId: undefined,
      firstName: undefined,
      lastName: undefined,
      mrn: undefined,
      pcpFirstName: undefined,
      pcpLastName: undefined,
      pcpTitle: undefined,
      pcpSuffix: undefined,
      settingsData: undefined,
      isLoading: false,
      inputPhone: undefined,
      error: undefined
    };
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    getUserData() {
      this.isLoading = true;
      const method = "get";
      const path = document.config.SITGetUserData + this.accountId + "/channel/sms/accountchannel";

      httpSend({ path, method, authToken: true })
        .then(response => {
          this.isLoading = false;
          this.settingsData = response.data;
          this.inputPhone = displayPhoneNumber(response.data.identifier);
        })
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to get user settings data",
              message: "Please try again later",
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    checkForm() {
      if (!this.inputPhone || !isValidPhoneNumber(this.inputPhone)) {
        this.error = "Please enter a valid phone number for this user";
      } else {
        this.error = undefined;
        this.settingsData.identifier = formatPhoneNumber(this.inputPhone);

        this.update();
      }
    },
    update() {
      this.isLoading = true;
      const method = "put";
      const path = document.config.SITUpdateUserData;
      const payload = this.settingsData;

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "User phone was successfully updated",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          this.$ionic.popoverController.dismiss();
          EventBus.$emit("reloadRosterView");
        })
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to change user phone number",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}

ion-card.prime-patient-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  transition: all 0.2s ease-in-out;
}

ion-card.prime-patient-card .prime-patient-card-name {
  font-size: 16px;
  font-weight: 500;
}

ion-card.prime-patient-card .prime-patient-card-info {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  color: var(--ion-color-medium-shade);
}

ion-card.prime-patient-card .prime-patient-card-name,
ion-card.prime-patient-card .prime-patient-card-info {
  width: 100%;
  max-width: 310px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prime-patient-card .avatar {
  height: 45px;
  width: 45px;
  min-width: 25px;
  font-size: 16px;
  margin-right: 5px;
}
.mrn-holder {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: bottom;
}
.mrn-holder::after {
  content: " ";
  margin-right: 5px;
}
</style>
