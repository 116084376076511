// for formatting phone before sending to server

export default num => {
  const numericOnly = num
    .replace(" ", "")
    .split("")
    .filter(char => !isNaN(char))
    .join("");
  const formattedNumber = /^1\d{10}$/.test(numericOnly) ? "+" + numericOnly : "+1" + numericOnly;

  return formattedNumber;
};
